<template>
  <div>
    <vue-headful :title="pageTitle"/>
    <div id="MyAccount" class="has-text-centered has-background-primary">
      <h1
        class="is-size-6 has-text-white"
        style="padding: 5px 0px"
      >{{ pageheading.toLocaleUpperCase() }}</h1>
    </div>
    <div v-if="isLoading">
      <Loading/>
    </div>
    <div v-else id="body-content-area-all">
      <div
        v-show="availableYears.length < 1"
        style="padding-top: 50px;"
        class="is-size-6 has-text-centered has-text-weight-bold"
      >No Reports available for {{institution.name}}.</div>
      <div
        v-show="availableYears.length > 0"
        id="subtitle-section"
        style="display:flex; flex-flow: column wrap; align-items: center;"
      >
        <div class="field is-grouped">
          <div>
            <h2
              id="subtitle"
              class="is-size-4 has-text-weight-bold"
            >Reports and Documents for {{institution.name}} for</h2>
          </div>
          <div
            class="has-text-accent"
            style="margin-left: 10px; border-bottom: 2px solid; position: relative; top: -10px; "
          >
            <select
              class="select is-large has-text-accent has-text-weight-bold"
              v-model="input.year"
              style="border: none;"
              @change="getReports"
            >
              <option v-for="year in availableYears" :value="year" :key="year">
                <h2>{{ year }}</h2>
              </option>
            </select>
          </div>
        </div>
      </div>

      <div v-show="availableYears.length > 0" id="pagelayout" class="columns">
        <div id="col1" class="column is-3"></div>
        <div id="col2" class="column">
          <div id="content-layout block">
            <div
              id="filter-section"
              class="block"
              style="padding-bottom: 20px; border-bottom: 1px solid #dbdbdb;"
            >
              <div>
                <a @click="expandAll" style="padding-right: 20px;">Expand All</a>
                <a @click="collapseAll">Collapse All</a>
              </div>
            </div>
            <div id="report-list-section" class="block">
              <div v-for="(reportType,index) in availReportTypes" v-bind:key="reportType">
                <b-collapse :open.sync="sectionstates[index]">
                  <div
                    :id="'report-type-'+reportType"
                    class="block"
                    slot="trigger"
                    style="padding-top: 20px;"
                  >
                    <div
                      :id="'report-type-'+reportType+'-name-section'"
                      class="field is-grouped"
                      style="margin-bottom: 0px;"
                    >
                      <div>
                        <h3
                          :id="'report-type-'+reportType+'-name'"
                          class="is-size-6"
                        >{{reportTypes.get(reportType).name}}</h3>
                      </div>
                      <div>
                        <a
                          class="is-size-6 has-text-primary"
                          v-show="sectionstates[index]"
                          style="position:relative; top:-4px;left: 6px;"
                        >
                          <b-icon slot="trigger" icon="caret-down" pack="fa"></b-icon>
                        </a>
                        <a
                          class="is-size-6 has-text-primary"
                          v-show="!sectionstates[index]"
                          style="position:relative; top:-4px;left: 6px;"
                        >
                          <b-icon slot="trigger" icon="caret-right" pack="fa"></b-icon>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    id="report-type-1-list-section"
                    class="block"
                    style="border-top: 1px solid #dbdbdb;"
                  >
                    <table class="table is-fullwidth">
                      <tr
                        v-for="report in reports.filter(r => r.typeCode == reportType)"
                        v-bind:key="report.id"
                      >
                        <td style="border:none;">
                          <!-- TODO: don't think this will work in prod (vue proxy handles during dev), need to either have api return full path or report path link  -->
                          <a :href="getReportPath(report.path)">{{report.displayName}}</a>
                        </td>
                        <td
                          class="has-text-right"
                          style="border:none;"
                        >{{ $moment.utc(report.uploadDate).format('MM/DD/YYYY') }}</td>
                      </tr>
                    </table>
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
        <div id="col3" class="column is-3"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import Loading from './Loading'
// TODO: dedupe
function trim (source, c) {
  if (c === ']') c = '\\]'
  if (c === '\\') c = '\\\\'
  return source.replace(new RegExp('^[' + c + ']+|[' + c + ']+$', 'g'), '')
}
let baseUrl = trim(process.env.VUE_APP_API_URL, '/')
baseUrl = trim(baseUrl, 'api')
baseUrl = trim(baseUrl, '/')

export default {
  components: {
    Loading
  },
  data () {
    return {
      input: {
        year: 2020
      },
      reports: [],
      reportTypes: new Map(),
      isLoading: false,
      sectionstates: [],
      availableYears: [2020],
      institution: {
        id: 0,
        name: ''
      },
      pageheading: 'My Account'
    }
  },
  async created () {
    // TODO: concurrent fetch awaitable tasks
    await this.GetInstitution()
    await this.GetSearchOptions()
    await this.getReportTypesLookup()

    await this.getReports()
  },
  watch: {
    availReportTypes () {
      this.sectionstates = this.availReportTypes.map(i => true)
    }
  },
  computed: {
    ...mapState(['clientSession', 'activeSite']),
    pageTitle () {
      return 'My Account' + ' - ' + this.activeSite.displayName
    },
    availReportTypes () {
      let allReportTypes = this.reports.map(r => r.typeCode)
      let availTypes = [...new Set(allReportTypes)].sort(
        (a, b) => this.reportTypes.get(a).order - this.reportTypes.get(b).order
      )
      return availTypes
    }
  },
  methods: {
    getReportPath (path) {
      // TODO: this will be removed once report.path has full https://* path
      path = trim(path, '/')
      return `${baseUrl}/${path}`
    },
    collapseAll () {
      this.sectionstates = this.sectionstates.map(i => false)
    },
    expandAll () {
      this.sectionstates = this.sectionstates.map(i => true)
    },
    async GetInstitution () {
      try {
        this.isLoading = true
        let route = `/institutions/${this.clientSession.institutionId}`
        let response = await this.axios.get(route)
        this.institution = response.data
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    async GetSearchOptions () {
      try {
        this.isLoading = true
        let route = `/reports/search/`
        let response = await this.axios.options(route)
        if (this.availableYears.length > 0) {
          this.availableYears = response.data.years.sort(function (a, b) { return b - a })
          let max = Math.max(...this.availableYears)
          if (this.input.year !== max) {
            this.input.year = Math.max(...this.availableYears)
          }
        }
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    async getReports () {
      try {
        if (
          !this.input.year ||
          this.input.year == null ||
          this.input.year < 1
        ) {
          return
        }
        this.isLoading = true
        let route = `/reports/search/`
        let payload = {
          filter: {
            yearOverride: {
              $eq: this.input.year
            }
          }
        }
        let response = await this.axios.post(route, payload)
        this.reports = response.data.records
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    async getReportTypesLookup () {
      try {
        let route = `/report-types`
        this.isLoading = true
        let response = await this.axios.get(route)
        this.reportTypes = new Map(response.data.records.map(i => [i.code, i]))
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
<style>
#pagelayout {
  width: 100%;
}

@media (max-width: 700px) {
  #MyAccount {
    display: none;
  }
}
</style>
